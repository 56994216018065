<template>
  <v-container fluid>
    <h1 class="text-h5 primary--text">
      Lista de componentes
    </h1>
    <v-divider />

    <v-card class="my-12">
      <template slot="progress">
        <v-progress-linear
          color="deep-purple"
          height="10"
          indeterminate
        />
      </template>

      <v-card-title>Lista de Gravações</v-card-title>

      <v-card-text>
        <AutocompleteEngravings
          v-model="forms.gravacaoSelecionada"
        />
      </v-card-text>

      <v-divider />

      <v-card-text>
        <pre>{{ forms.gravacaoSelecionada }}</pre>
      </v-card-text>
    </v-card>


    <v-card class="my-12">
      <template slot="progress">
        <v-progress-linear
          color="deep-purple"
          height="10"
          indeterminate
        />
      </template>

      <v-card-title>Lista de Produtos</v-card-title>

      <v-card-text>
        <AutocompleteSearchProducts
          v-model="forms.produtoSelecionado"
        />
      </v-card-text>

      <v-divider />

      <v-card-text>
        <pre>{{ forms.produtoSelecionado }}</pre>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import AutocompleteEngravings from '../../../components/bussiness-components/autocompletes/AutocompleteEngravings.vue'
import AutocompleteSearchProducts from '../../../components/bussiness-components/autocompletes/AutocompleteSearchProducts.vue';

export default {
  components: {
    AutocompleteEngravings,
    AutocompleteSearchProducts,
  },
  data() {
    return {
      forms:{
        gravacaoSelecionada: null,
        produtoSelecionado: null,
      }
    }
  },
  methods: {
   
  },
}
</script>
